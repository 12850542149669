import React, { ReactElement, useEffect, useState } from "react";
import { TabMenu } from "primereact/tabmenu";
import { useLocation, useNavigate } from "react-router-dom";
import TokenService from "services/token.service";

export function AdminNavbar(): ReactElement {
  const items = [
    { label: "Empresas", icon: "pi pi-fw pi-building" },
    { label: "Usuários", icon: "pi pi-fw pi-users" },
    { label: "Categorias", icon: "pi pi-fw pi-database" },
    { label: "Relatórios", icon: "pi pi-fw pi-folder" },
    { label: "Páginas", icon: "pi pi-fw pi-copy" },
    { label: "Consultas", icon: "pi pi-fw pi-database" },
    { label: "Filtros", icon: "pi pi-fw pi-filter" },
    { label: "Parametros", icon: "pi pi-fw pi-objects-column" },
    { label: "Painel", icon: "pi pi-fw pi-desktop" },
    { label: "Disparo de email", icon: "pi pi-fw pi-envelope" },
    { label: "Sair", icon: "pi pi-power-off" },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const routes = [
    "empresas",
    "usuarios",
    "categorias",
    "relatorios",
    "componentes",
    "consultas",
    "filtros",
    "parametros",
    "categoria",
    "disparo-email",
  ];
  const [activeIndex, setActiveIndex] = useState(() => {
    let index = 0;
    index = routes.findIndex((route) => location.pathname.includes(route));
    return index === -1 ? 0 : index;
  });

  const logout = (): void => {
    TokenService.removeToken(navigate);
  };

  useEffect(() => {
    if (!location.pathname.includes(routes[activeIndex])) {
      navigate(`./${routes[activeIndex]}`);
    }
    if (
      location.pathname.includes("admin/painel/selecionar") ||
      location.pathname.includes("painel/selecionar")
    ) {
      navigate("/categoria");
    }
  });

  return (
    <TabMenu
      model={items}
      activeIndex={activeIndex}
      onTabChange={(e) => {
        if (e.index === 10) {
          logout();
          return;
        }
        if (e.index === 8) {
          navigate("/categoria");
        }
        setActiveIndex(e.index);
      }}
    />
  );
}
